<template>
    <section class="relative chart-container" :class="loading? 'loading' : ''">
        <DatePicker v-model="formattedDateValue" @input="fetchDataForSelectedDate" :timeframe="timeframe"/>
        <div class="chart-wrapper">
            <canvas id="today-chart" ref="today" class="chart-canvas"></canvas>
        </div>
    </section>
</template>

<style lang="scss">
    .chart-wrapper {
        position: relative;
        width: 100%;
        height: 400px;
    }
    .datepicker {
        position: absolute !important;
        top: 2.5rem;
        left: 3rem;
    }
    .chart-canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .chart-container{
        background: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px;
    }
    .loading{
        background-color: lightgray;
        opacity: 50%;
    }
</style>

<script>
    import DatePicker from './DatePicker.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        methods: {
            ...mapActions(['getToday', 'getDataByTimeframe']),
            initChart() {
                if (this.chartInstance) {
                    this.chartInstance.destroy();
                }

                const todayCtx = this.$refs.today.getContext('2d');

                Chart.defaults.font.size = 20;
                Chart.defaults.font.weight = '600';
                Chart.defaults.color = '#000';
                
                // const todayCtx = document.querySelector('#today-chart').getContext('2d');
                this.chartInstance = new Chart(todayCtx, {
                    type: 'bar',
                    data: {
                        labels: this.agentsLabel,
                        datasets: [{
                            label: '',
                            data: this.agentsData,
                            backgroundColor:['#81D353', '#42A5F5', '#FFA726', '#26A69A', '#EF5350', '#AB47BC', '#E3D65C']
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: {
                            duration: 1,
                            onComplete: function () {
                                const ctx = todayCtx;
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'bottom';
                                // setTimeout(() => {
                                //     this.data.datasets.forEach(function (dataset, i) {
                                //     var meta = todayChart._metasets[i];
                                //     meta.data.forEach(function (bar, index) {
                                //             var data = dataset.data[index];                   
                                //             ctx.fillText(data, bar.x, bar.y + ((bar.base - bar.y) / 2));
                                //         });
                                //     });
                                // }, 100)
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                padding: 20,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                titleFont: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                bodyFont: {
                                    size: 12
                                },
                                borderColor: '#4A4A4A',
                                borderWidth: 1
                            }
                        },
                        scales: {        
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 37.5,
                                    color: '#9e9e9e'
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks:{
                                    color: '#9e9e9e'
                                }
                            }
                        },
                        interaction: {
                            intersect: false,
                        },
                    }
                });
            },
            fetchDataForSelectedDate(date) {
                date = date.toLocaleDateString();
                this.timeframe = 'day'
                this.getDataByTimeframe({ date, timeframe: this.timeframe });
            }
        },
        components: {
            DatePicker
        },
        // computed: {
        //     ...mapGetters(['today']),
        //     agentsLabel() {
        //         return this.today.map(el => el.name);
        //     },
        //     agentsData() {
        //         return this.today.map(el => el.count);
        //     }
        // },
        computed: {
            ...mapGetters(['today', 'loading']),
            agentsLabel() {
                return this.filteredData.map(el => el.name);
            },
            agentsData() {
                return this.filteredData.map(el => el.count);
            },
            formattedDateValue: {
                get() {
                    return this.dateValue.toISOString().split('T')[0]; // Formats date to 'YYYY-MM-DD'
                },
                set(newVal) {
                    this.dateValue = new Date(newVal);
                }
            }
        },
        data() {
            return {
                filteredData: [],
                dataLoaded: false,
                chartInstance: null,
                dateValue: new Date(),
                timeframe: 'day',
            };
        },
        watch: {
            immediate: true,
            timeframe: {
                immediate: true,
                handler(val){
                    const currentDate = new Date().toLocaleDateString();
                    this.getDataByTimeframe({ date: currentDate, timeframe: val });
                }
            },
            today: {
                immediate: true,
                handler(val) {
                    this.filteredData = val.filter(el => ['Elton M.', 'Elton R.', 'Sam', 'Falk', 'Dena', 'Lisa ', 'Veton'].includes(el.name));
                    if (this.filteredData.length > 0) {
                        this.$nextTick(() => {
                            this.initChart();
                        });
                    }
                }
            }
        },

        mounted() {
            this.getToday();
            let unsubscribe = null
            unsubscribe = this.$store.subscribe(({ type }) => {
                if (type === 'setToday') {
                    this.dataLoaded = true;
                    this.$nextTick(() => {
                        this.initChart();
                        unsubscribe();
                    });
                    // this.initChart()
                }
            })
            
        },
        beforeUnmount() {
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }
            // const todayCtx = document.querySelector('#today-chart').getContext('2d');
            // todayCtx.destroy();
        }
    }
</script>