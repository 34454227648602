<template>
    <section class="relative chart-container" :class="loading? 'loading' : ''">
        <DatePicker v-model="dateValue" @input="fetchDataForSelectedDate" :timeframe="timeframe" />
        <div class="chart-wrapper">
            <canvas id="hour-chart" ref="hour" class="chart-canvas"></canvas>
        </div>
    </section>
</template>

<style lang="scss">
    .chart-wrapper {
        position: relative;
        width: 100%;
        height: 400px;
    }
    .chart-canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .datepicker {
        position: absolute !important;
        top: 2.5rem;
        left: 3rem;
    }
    .chart-container{
        position: relative;
        height: 100%;
        width: 100%;
        background: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px 10px;
    }
    .loading{
        background-color: lightgray;
        opacity: 50%;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DatePicker from './DatePicker.vue';
    export default {
        data() {
            return {
                showDate: false,
                dateValue: new Date().toLocaleDateString(),
                // dateValue: new Date(),
                hourChart: null,
                filteredData: [],
                dataLoaded: false,
                chartInstance: null,
                activeUsers: ['Elton M.', 'Elton R.', 'Sam', 'Falk', 'Dena', 'Lisa ', 'Veton'], // List of active users
                timeframe: 'hour',
            }
        },
        components: {
            DatePicker
        },
        methods: {
            ...mapActions(['getHour', 'getHourByDate', 'getDataByTimeframe']),
            closeDate() {
                this.showDate = false;
            },
            initChart() {
                if(this.hourChart) this.hourChart.destroy();

                const hourCtx = this.$refs.hour.getContext('2d');

                Chart.defaults.font.size = 20;
                Chart.defaults.font.weight = '600';
                Chart.defaults.color = '#000';
                
                // const hourCtx = document.querySelector('#hour-chart').getContext('2d');
                this.hourChart = new Chart(hourCtx, {
                    type: 'line',
                    data: {
                        labels: this.labels,
                        datasets: this.datasets
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {        
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: '#9e9e9e'
                                }
                            },
                            x: {
                                grid: {
                                    display: true,
                                    color: '#d0d0d0',
                                    borderDash: [2, 8]
                                },
                                ticks:{
                                    color: '#9e9e9e'
                                },
                                // min: '06',  // Start at 6 AM // uncomment this and on line 135, 137 to show data only for specific hours
                                // max: '21',  // End at 9 PM
                                // labels: this.labels.slice(6, 22) // Show hours from 6 AM to 9 PM
                            }
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    boxHeight: 2,
                                    padding: 10,
                                    boxWidth: 20,
                                    padding: 20,
                                    color: '#4A4A4A'
                                },
                            },
                            tooltip: {
                                padding: 20,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                titleFont: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                bodyFont: {
                                    size: 12
                                },
                                borderColor: '#4A4A4A',
                                borderWidth: 1
                            }
                        },
                        elements: {
                            point: {
                                radius: 5,
                                hoverRadius: 8,
                            }
                        },
                        interaction: {
                            intersect: false,
                        },
                        animation: {
                            duration: 800,
                            easing: 'easeInOutQuart'
                        }
                    },
                });
            },
            fetchDataForSelectedDate(date) {
                date = date.toLocaleDateString();
                this.timeframe = 'hour'
                this.getDataByTimeframe({ date, timeframe: this.timeframe });
            }
        },
        computed: {
            ...mapGetters(['hour', 'loading']),
            labels() {
                const hours = [];
                for(let i = 0; i <= 23; i++) {
                    if(i >= 10) {
                        hours.push(`${i}`);
                    }
                    else {
                        hours.push(`0${i}`);
                    }
                }
                return hours;
            },
            agentsLabel() {
                return this.filteredData.map(el => el.name);
            },
            agentsData() {
                return this.filteredData.map(el => el.count);
            },
            datasets() {
                const colors = ['#81D353', '#42A5F5', '#FFA726', '#26A69A', '#EF5350', '#AB47BC', '#E3D65C'];
                return this.filteredData.map((el, idx) => {
                    const data = [];
                    Object.keys(el.hourly).sort().forEach(key => {
                        // if (parseInt(key) >= 6 && parseInt(key) <= 21) { // uncomment this and on line 78-80 to show data only for specific hours
                            data.push(el.hourly[key]);
                        // }
                    });
                    return {
                        label: el.name,
                        data,
                        backgroundColor: colors[idx],
                        borderColor: colors[idx],
                        borderWidth: 2,
                        pointRadius: 5,
                        // tension: 0.2,
                    }
                })
            },
        },
        mounted() {
            this.getHour();
            this.$store.subscribe(({ type }) => {
                if (type === 'setHour') {
                    this.initChart();
                }
            })
        },
        watch: {
            timeframe: {
                immediate: true,
                handler(val){
                    const currentDate = new Date().toLocaleDateString();
                    this.getDataByTimeframe({ date: currentDate, timeframe: val });
                }
            },
            hour: {
                immediate: true,
                handler(val) {
                    this.filteredData = val.filter(el => this.activeUsers.includes(el.name));
                    if (this.filteredData.length > 0) {
                        this.$nextTick(() => {
                            this.initChart();
                        });
                    }
                }
            }
        }
    }
</script>