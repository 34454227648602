<template>
    <section class="relative chart-container" :class="loading? 'loading' : ''">
        <DatePicker v-model="dateValue" @input="fetchDataForSelectedDate" :timeframe="timeframe"/>
        <div class="chart-wrapper">
            <canvas id="week-chart" ref="week"></canvas>
        </div>
    </section>
</template>

<style lang="scss">
    .chart-wrapper {
        position: relative;
        width: 100%;
        height: 400px;
    }
    .chart-canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .datepicker {
        position: absolute !important;
        top: 2.5rem;
        left: 3rem;
    }
    .chart-container{
        background: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 1rem;
    }
    .loading{
        background-color: lightgray;
        opacity: 50%;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DatePicker from './DatePicker.vue';
    export default {
        data() {
            return {
                dateValue: new Date().toLocaleDateString(),
                weekChart: null,
                filteredData: [],
                dataLoaded: false,
                chartInstance: null,
                activeUsers: ['Elton M.', 'Elton R.', 'Sam', 'Falk', 'Dena', 'Lisa ', 'Veton'], // List of active users
                timeframe: 'week',
            }
        },
        components: {
            DatePicker
        },
        methods: {
            ...mapActions(['getWeek', 'getDataByTimeframe']),
            initChart() {
                if (this.weekChart) this.weekChart.destroy();
                const weekCtx = this.$refs.week.getContext('2d');
                Chart.defaults.font.size = 16;
                Chart.defaults.font.weight = '600';
                Chart.defaults.color = '#4A4A4A';

                // const weekCtx = document.querySelector('#week-chart').getContext('2d');
                this.weekChart = new Chart(weekCtx, {
                    type: 'bar',
                    data: {
                        labels: this.labels,
                        datasets: this.datasets
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: '#9e9e9e',
                                },
                                grid: {
                                    color: '#e0e0e0',
                                    borderDash: [5, 5]
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    color: '#9e9e9e',
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    boxHeight: 6,
                                    boxWidth: 20,
                                    font: {
                                        size: 14
                                    },
                                    color: '#4A4A4A'
                                }
                            },
                            tooltip: {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                titleFont: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                bodyFont: {
                                    size: 12
                                },
                                padding: 10,
                                borderColor: '#4A4A4A',
                                borderWidth: 1
                            }
                        },
                        animation: {
                            duration: 800,
                            easing: 'easeInOutQuart'
                        },
                        interaction: {
                            intersect: false,
                        },
                    }
                });
            },
            fetchDataForSelectedDate(date) {
                date = date.toLocaleDateString();
                this.timeframe = 'week'
                this.getDataByTimeframe({ date, timeframe: this.timeframe });
            },
        },
        computed: {
            ...mapGetters(['week', 'loading']),
            labels() {
                return ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
            },
            datasets() {
                const colors = ['#81D353', '#42A5F5', '#FFA726', '#26A69A', '#EF5350', '#AB47BC', '#E3D65C'];
                return this.filteredData.map((el, idx) => ({
                    label: el.name,
                    data: el.weekly,
                    backgroundColor: colors[idx],
                    borderColor: '#ffffff',
                    borderWidth: 2,
                    hoverBackgroundColor: colors[idx],
                    hoverBorderColor: '#ffffff',
                    borderRadius: 4, // Rounded corners
                    barThickness: 15, // Width of the bars
                    borderSkipped: false, // Rounded corners for all sides
                    tension: 0.2,
                }));
            }
        },
        mounted() {
            this.getWeek();
            this.$store.subscribe(({ type }) => {
                if (type === 'setWeek') {
                    this.initChart();
                }
            })
            
        },
        watch: {
            timeframe: {
                immediate: true,
                handler(val){
                    const currentDate = new Date().toLocaleDateString();
                    this.getDataByTimeframe({ date: currentDate, timeframe: val });
                }
            },
            week: {
                immediate: true,
                handler(val){
                    this.filteredData = val.filter(el => this.activeUsers.includes(el.name));
                    if (this.filteredData.length > 0) {
                        this.$nextTick(() => {
                            this.initChart();
                        });
                    }
                }
            }
        }
    }
</script>