<template>
    <section class="relative chart-container" :class="loading? 'loading' : ''">
        <DatePicker v-model="dateValue" @input="fetchDataForSelectedDate" :timeframe="timeframe" />
        <div class="chart-wrapper">
            <canvas id="month-chart" ref="month" class="chart-canvas"></canvas>
        </div>
    </section>
</template>

<style lang="scss">
    .chart-wrapper {
        position: relative;
        width: 100%;
        height: 400px; /* Define a fixed height to ensure responsiveness */
    }
    .chart-canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .datepicker {
        position: absolute !important;
        top: 2.5rem;
        left: 3rem;
    }
    .chart-container{
        background: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px 10px;
    }
    .loading{
        background-color: lightgray;
        opacity: 50%;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DatePicker from './DatePicker.vue';

    export default {
        data() {
            return {
                showDate: false,
                dateValue: new Date().toLocaleDateString(),
                monthChart: null,
                filteredData: [],
                dataLoaded: false,
                chartInstance: null,
                activeUsers: ['Elton M.', 'Elton R.', 'Sam', 'Falk', 'Dena', 'Lisa ', 'Veton'], // List of active users
                timeframe: 'month',
            }
        },
        components: {
            DatePicker
        },
        methods: {
            ...mapActions(['getMonth', 'getDataByTimeframe']),
            closeDate() {
                this.showDate = false;
            },
            initChart() {
                if(this.monthChart) this.monthChart.destroy();
                
                const monthCtx = this.$refs.month.getContext('2d');
                
                Chart.defaults.font.size = 20;
                Chart.defaults.font.weight = '600';
                Chart.defaults.color = '#000';
                // const monthCtx = document.querySelector('#month-chart').getContext('2d');
                this.monthChart = new Chart(monthCtx, {
                    type: 'bar',
                    data: {
                        labels: this.labels,
                        datasets: this.datasets
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {        
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                    stepSize: 5,
                                    color: '#9e9e9e'
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks:{
                                    color: '#9e9e9e'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    boxHeight: 1,
                                    padding: 10,
                                    boxWidth: 20,
                                    color: '#4A4A4A'
                                },
                            },
                            tooltip: {
                                padding: 20,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                titleFont: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                bodyFont: {
                                    size: 12
                                },
                                borderColor: '#4A4A4A',
                                borderWidth: 1
                            }
                        },
                        barThickness: 20,
                        categoryPercentage: 0.6,
                        barPercentage: 0.7,
                        interaction: {
                            intersect: false,
                        },
                    }
                });
            },
            fetchDataForSelectedDate(date) {
                date = date.toLocaleDateString();
                this.timeframe = 'month'
                this.getDataByTimeframe({ date, timeframe: this.timeframe });
            }
        },
        computed: {
            ...mapGetters(['month', 'loading']),
            labels() {
                return ['Woche 1', 'Woche 2', 'Woche 3', 'Woche 4'];
            },
            agentsLabel() {
                return this.filteredData.map(el => el.name);
            },
            agentsData() {
                return this.filteredData.map(el => el.count);
            },
            datasets() {
                const colors = ['#81D353', '#42A5F5', '#FFA726', '#26A69A', '#EF5350', '#AB47BC', '#E3D65C'];
                return this.filteredData.map((el, idx) => {
                    return {
                        label: el.name,
                        data: el.monthly,
                        backgroundColor: colors[idx],
                        borderColor: colors[idx],
                        borderWidth: 2,
                        pointRadius: 0.5
                    }
                })
            }
        },
        mounted() {
            this.getMonth();
            this.$store.subscribe(({ type }) => {
                if (type === 'setMonth') {
                    this.initChart();
                }
            })
            
        },
        watch: {
            timeframe: {
                immediate: true,
                handler(val){
                    const currentDate = new Date().toLocaleDateString();
                    this.getDataByTimeframe({ date: currentDate, timeframe: val });
                }
            },
            month: {
                immediate: true,
                handler(val){
                    this.filteredData = val.filter(el => this.activeUsers.includes(el.name));
                    if (this.filteredData.length > 0) {
                        this.$nextTick(() => {
                            this.initChart();
                        });
                    }
                }
            }
        }
    }
</script>