<template>
    <section class="relative chart-container" :class="loading? 'loading' : ''">
        <div class="chart-wrapper">
            <canvas id="decade-chart" ref="decade" class="chart-canvas"></canvas>
        </div>
    </section>
</template>

<style lang="scss">
    .chart-wrapper {
        position: relative;
        width: 100%;
        height: 400px; /* Define a fixed height to ensure responsiveness */
    }
    .chart-canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .datepicker {
        position: absolute !important;
        top: 2.5rem;
        left: 3rem;
    }
    .chart-container{
        background: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px 10px;
    }
    .loading{
        background-color: lightgray;
        opacity: 50%;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                // showDate: false,
                // dateValue: new Date(),
                decadeChart: null,
                chartInstance: null,
                dataLoaded: false,
                filteredData: [],
                activeUsers: ['Elton M.', 'Elton R.', 'Sam', 'Falk', 'Dena', 'Lisa ', 'Veton'] // List of active users
            }
        },
        methods: {
            ...mapActions(['getDecade']),
            // closeDate() {
            //     this.showDate = false;
            // },
            initChart() {
                if(this.decadeChart) this.decadeChart.destroy();
                
                const decadeCtx = this.$refs.decade.getContext('2d');
                
                Chart.defaults.font.size = 20;
                Chart.defaults.font.weight = '600';
                Chart.defaults.color = '#000';
                // const decadeCtx = document.querySelector('#decade-chart').getContext('2d');
                this.decadeChart = new Chart(decadeCtx, {
                    type: 'bar',
                    data: {
                        labels: this.labels,
                        datasets: this.datasets
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {        
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                    stepSize: 5,
                                    color: '#9e9e9e'
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks:{
                                    color: '#9e9e9e'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    boxHeight: 1,
                                    padding: 10,
                                    boxWidth: 20,
                                    padding: 20
                                },
                            },
                            tooltip: {
                                padding: 20,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                titleFont: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                bodyFont: {
                                    size: 12
                                },
                                borderColor: '#4A4A4A',
                                borderWidth: 1
                            }
                        },
                        interaction: {
                            intersect: false,
                        },
                    }
                });
            }
        },
        computed: {
            ...mapGetters(['decade', 'loading']),
            labels() {
                const year = new Date().getFullYear();
                return [year - 5, year - 4, year - 3, year - 2, year - 1, year];
            },
            agentsLabel() {
                return this.filteredData.map(el => el.name);
            },
            agentsData() {
                return this.filteredData.map(el => el.count);
            },
            datasets() {
                const colors = ['#81D353', '#42A5F5', '#FFA726', '#26A69A', '#EF5350', '#AB47BC', '#E3D65C'];
                return this.filteredData.map((el, idx) => {
                    return {
                        label: el.name,
                        data: el.decade,
                        backgroundColor: colors[idx],
                        borderColor: colors[idx],
                        borderWidth: 2,
                        pointRadius: 0.5
                    }
                })
            }
        },
        mounted() {
            this.getDecade();
            this.$store.subscribe(({ type }) => {
                if (type === 'setDecade') {
                    this.initChart();
                }
            })
            
        },
        beforeUnmount() {
            // const todayCtx = document.querySelector('#today-chart').getContext('2d');
            // todayCtx.destroy();
        },
        watch: {
            // dateValue: function(newVal, oldVal) {
            //     console.log('here');
            //     this.getHourByDate(newVal);
            // }
            decade:{
                immediate: true,
                handler(val){
                    this.filteredData = val.filter(el => this.activeUsers.includes(el.name));
                    if (this.filteredData.length > 0) {
                        this.$nextTick(() => {
                            this.initChart();
                        });
                    }
                }
            }
        }
    }
</script>